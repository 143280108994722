import { Spin } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRoutes } from "react-router-dom"
import STORAGE, { getStorage } from "src/lib/storage"
import { StoreContext } from "src/lib/store"
import CommonService from "src/services/CommonService"
import "./App.css"
import ModalLoading from "./components/Modal/Loading"
import { getListSystemKey, getStaticNav, setListTabs } from "./redux/appGlobal"
import ROUTER from "./router"
import GuestServices from "./services/GuestServices"
import RoleService from "./services/RoleService"
import Resume from "./pages/USER/Resume"

// ANONYMOUS
const LoginGoogle = React.lazy(() => import("./pages/LoginGoogle"))
const PublicRouters = React.lazy(() => import("./pages/PublicRouters"))
const Home = React.lazy(() => import("./pages/ANONYMOUS/Home"))
const News = React.lazy(() => import("./pages/ANONYMOUS/News"))
const Tech = React.lazy(() => import("./pages/ANONYMOUS/News/Tech"))
const DetailTech = React.lazy(() => import("./pages/ANONYMOUS/News/TechDetal"))
const Search = React.lazy(() => import("./pages/Search"))
const Order = React.lazy(() => import("./pages/ADMIN/Order"))
const RequestBallot = React.lazy(() => import("./pages/ADMIN/RequestBallot"))
const NewsDetail = React.lazy(() => import("./pages/ANONYMOUS/NewsDetail"))
const Contact = React.lazy(() => import("./pages/ANONYMOUS/Contact"))
const RequestSupport = React.lazy(() => import("./pages/ADMIN/RequestSupport"))
const Specialize = React.lazy(() => import("./components/Card/Specialize"))
const SvgViewer = React.lazy(() => import("./pages/SvgViewer"))
const NotFound = React.lazy(() => import("./pages/NotFound"))

const IntroductionOverview = React.lazy(() =>
  import("./pages/ANONYMOUS/IntroductionOverview"),
)
const Seminar = React.lazy(() =>
  import("./pages/ANONYMOUS/ResultResearch/Seminar"),
)

const DetailSeminar = React.lazy(() =>
  import("./pages/ANONYMOUS/ResultResearch/Seminar/Detail"),
)
const RegisterSeminar = React.lazy(() =>
  import("./pages/ANONYMOUS/ResultResearch/Seminar/Register"),
)
const TrainingNews = React.lazy(() => import("./pages/ANONYMOUS/Training/News"))
const Course = React.lazy(() => import("./pages/ANONYMOUS/Course"))
const RegisterCourse = React.lazy(() => import("./pages/USER/RegisterCourse"))
const DetailCourse = React.lazy(() => import("./pages/ANONYMOUS/Course/Detail"))
const Regulation = React.lazy(() => import("./pages/ANONYMOUS/Regulation"))
const Examination = React.lazy(() => import("./pages/ANONYMOUS/Examination"))
const ExaminationRegister = React.lazy(() =>
  import("./pages/ANONYMOUS/Examination/Register"),
)
const ExaminationServices = React.lazy(() =>
  import("./pages/ANONYMOUS/Examination/ExaminationServices"),
)
const Targets = React.lazy(() =>
  import("./pages/ANONYMOUS/Examination/Targets"),
)
const QCVNTargetPrice = React.lazy(() =>
  import("./pages/ANONYMOUS/Examination/QCVNTargetPrice"),
)
const GetSample = React.lazy(() =>
  import("./pages/ANONYMOUS/Examination/GetSample"),
)
const Result = React.lazy(() => import("./pages/ANONYMOUS/Examination/Result"))
const Monitoring = React.lazy(() => import("./pages/ANONYMOUS/Monitoring"))
const Services = React.lazy(() => import("./pages/ANONYMOUS/Services"))
const Recruitment = React.lazy(() => import("./pages/ANONYMOUS/Recruitment"))
const DetailRecruitmentSession = React.lazy(() =>
  import("./pages/ANONYMOUS/Recruitment/Detail"),
)
const DetailRecruitmentPosition = React.lazy(() =>
  import("./pages/ANONYMOUS/Recruitment/Detail/DetailRecruitWork"),
)
const RecruitmentRegister = React.lazy(() =>
  import("./pages/ANONYMOUS/Recruitment/RecruitmentRegister"),
)
const ServiceOnsite = React.lazy(() =>
  import("./pages/ANONYMOUS/ServiceOnsite"),
)
const ServicePage = React.lazy(() => import("./pages/ANONYMOUS/ServicePage"))
// USER
const PrivateRoutes = React.lazy(() => import("./pages/PrivateRoutes"))
const CardShopping = React.lazy(() => import("./pages/USER/CardShopping"))
const PayDelivery = React.lazy(() => import("./pages/USER/Pay&Delivery"))
const MyCourse = React.lazy(() => import("./pages/USER/Course"))
const MyOrder = React.lazy(() => import("./pages/USER/Order"))
const MyAccount = React.lazy(() => import("./pages/USER/MyAccount"))
const BuyProduct = React.lazy(() => import("./pages/USER/BuyProduct"))
const Dashboard = React.lazy(() => import("./pages/USER/Dashboard"))
// ADMIN
const AminRoutes = React.lazy(() => import("./pages/ADMIN/AminRoutes"))
const CreatePost = React.lazy(() => import("./pages/ADMIN/CreatePost"))
const Department = React.lazy(() => import("./pages/ADMIN/Department"))
const ListUser = React.lazy(() => import("./pages/ADMIN/ListUser"))
const PostManager = React.lazy(() => import("./pages/ADMIN/PostManager"))
const Role = React.lazy(() => import("./pages/ADMIN/Role"))
const CustomerDirectory = React.lazy(() =>
  import("./pages/ADMIN/CustomerDirectory"),
)
const Tags = React.lazy(() => import("./pages/ADMIN/Tags"))
const Price = React.lazy(() => import("./pages/ADMIN/Price"))
const ProductManagement = React.lazy(() => import("./pages/ADMIN/Product"))
const ProductServices = React.lazy(() =>
  import("./pages/ADMIN/ProductServices"),
)
const ServicesRequest = React.lazy(() =>
  import("./pages/ADMIN/ServicePost/ServiceRequest"),
)
const ListTarget = React.lazy(() => import("./pages/ADMIN/ListTarget"))
const Package = React.lazy(() => import("./pages/ADMIN/Package"))
const CourseManagement = React.lazy(() => import("./pages/ADMIN/Course"))
const Product = React.lazy(() => import("./pages/ANONYMOUS/Product"))
const DetailProduct = React.lazy(() =>
  import("./pages/ANONYMOUS/Product/Detail"),
)
const TargetPrice = React.lazy(() => import("./pages/ADMIN/TargetPrice"))
const GetResultAdmin = React.lazy(() => import("./pages/ADMIN/GetResult"))
const DiplomaRequest = React.lazy(() => import("./pages/ADMIN/DiplomaRequest"))
const SeminarAdmin = React.lazy(() => import("./pages/ADMIN/Seminar"))
const Business = React.lazy(() => import("./pages/ADMIN/Business"))
const ListContact = React.lazy(() => import("./pages/ADMIN/ListContact"))
const RecruitmentAdmin = React.lazy(() => import("./pages/ADMIN/Recruitment"))
const DetailRecruit = React.lazy(() =>
  import("./pages/ADMIN/Recruitment/detail"),
)
const StatisticDetail = React.lazy(() =>
  import("./pages/ADMIN/StatisticDetail"),
)
const StatisticDetailTesting = React.lazy(() =>
  import(
    "./pages/ADMIN/StatisticDetail/components/PagesDetail/StatisticDetailTesting"
  ),
)
const StatisticDetailListSample = React.lazy(() =>
  import(
    "./pages/ADMIN/StatisticDetail/components/PagesDetail/StatisticDetailTesting/ListSample"
  ),
)
const StatisticDetailTestPoint = React.lazy(() =>
  import("./pages/ADMIN/StatisticDetail/components/TestPointDetail"),
)
const DetailTestRequest = React.lazy(() =>
  import("./pages/ADMIN/DetailTestRequest"),
)
const ServicePost = React.lazy(() => import("./pages/ADMIN/ServicePost"))
const BusinessHour = React.lazy(() => import("./pages/ADMIN/BusinessHour"))
const ProfilePage = React.lazy(() => import("./pages/ADMIN/Curriculumvitae"))
const Infrastructure = React.lazy(() => import("./pages/ADMIN/Infrastructure"))
const SalaryManager = React.lazy(() => import("./pages/ADMIN/SalaryManager"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          <Spin />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

function App() {
  const { userStore } = useContext(StoreContext)
  const [, setUser] = userStore
  const isLogin = getStorage(STORAGE.TOKEN)
  const dispatch = useDispatch()
  const { modalLoading } = useSelector(state => state.common)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getSystemKey()
  }, [])

  useEffect(() => {
    if (!!isLogin) getData()
  }, [isLogin])

  const getSystemKey = async () => {
    const res = await CommonService.getSystemKey("All")
    if (res.IsError) return
    dispatch(getListSystemKey(res.Object))
  }
  const getData = async () => {
    try {
      setLoading(true)
      setUser(getStorage(STORAGE.USER_INFO))
      if (getStorage(STORAGE.USER_INFO).AccountType !== 2) {
        const resp = await RoleService.getListTab()
        if (resp.isOk) {
          dispatch(setListTabs(resp.Object))
        }

        // const responsiveTask = await RoleService.getListTask()
        // if (responsiveTask.isOk) {
        //   dispatch(setListCount(responsiveTask.Object))
        // }
      }
      getSystemKey()
      const ress = await GuestServices.getStaticNav()
      if (ress.IsError) return
      dispatch(getStaticNav(ress.Object))
    } finally {
      setLoading(false)
    }
  }
  const routes = [
    {
      path: ROUTER.SVG_VIEWER,
      element: (
        <LazyLoadingComponent>
          <SvgViewer />
        </LazyLoadingComponent>
      ),
    },
    {
      path: ROUTER.LOGIN_GOOGLE,
      element: (
        <LazyLoadingComponent>
          <LoginGoogle />
        </LazyLoadingComponent>
      ),
    },
    // ADMIN
    {
      element: (
        <LazyLoadingComponent>
          <AminRoutes />
        </LazyLoadingComponent>
      ),
      children: [
        {
          path: ROUTER.DANG_BAI,
          element: (
            <LazyLoadingComponent>
              <CreatePost />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TUYEN_DUNG,
          element: (
            <LazyLoadingComponent>
              <RecruitmentAdmin />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_TUYEN_DUNG,
          element: (
            <LazyLoadingComponent>
              <DetailRecruit />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_CHI_TIEU_PHAN_TICH,
          element: (
            <LazyLoadingComponent>
              <ListTarget Type={2} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_BA,
          element: (
            <LazyLoadingComponent>
              <ListContact />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.YEU_CAU_HO_TRO,
          element: (
            <LazyLoadingComponent>
              <RequestSupport />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TRA_KET_QUA_ADMIN,
          element: (
            <LazyLoadingComponent>
              <GetResultAdmin />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.GIA_CHI_TIEU,
          element: (
            <LazyLoadingComponent>
              <TargetPrice />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_CHI_TIEU,
          element: (
            <LazyLoadingComponent>
              <ListTarget Type={1} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.QUAN_LY_KHOA_HOC,
          element: (
            <LazyLoadingComponent>
              <CourseManagement />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_HOI_THAO,
          element: (
            <LazyLoadingComponent>
              <SeminarAdmin />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_GOI_XET_NGHIEM,
          element: (
            <LazyLoadingComponent>
              <Package Type={1} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_GOI_QTMT,
          element: (
            <LazyLoadingComponent>
              <Package Type={2} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.PHONG_BAN_CHUC_VU,
          element: (
            <LazyLoadingComponent>
              <Department />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DON_HANG,
          element: (
            <LazyLoadingComponent>
              <Order />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_YEU_CAU,
          element: (
            <LazyLoadingComponent>
              <RequestBallot />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.QUAN_LY_SAN_PHAM,
          element: (
            <LazyLoadingComponent>
              <ProductManagement />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.DON_GIA_DINH_MUC,
          element: (
            <LazyLoadingComponent>
              <Price />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_MUC_THE,
          element: (
            <LazyLoadingComponent>
              <Tags />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.PHAN_QUYEN,
          element: (
            <LazyLoadingComponent>
              <Role />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_BAI_VIET,
          element: (
            <LazyLoadingComponent>
              <PostManager />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_BA_NGUOI_DUNG,
          element: (
            <LazyLoadingComponent>
              <ListUser />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_BA_KHACH_HANG,
          element: (
            <LazyLoadingComponent>
              <CustomerDirectory />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_PHIEU_XNN,
          element: (
            <LazyLoadingComponent>
              <DetailTestRequest RequestType={1} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_PHIEU_QTMT,
          element: (
            <LazyLoadingComponent>
              <DetailTestRequest RequestType={2} />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.DV_SAN_PHAM,
          element: (
            <LazyLoadingComponent>
              <ProductServices />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_PHIEU_DICH_VU,
          element: (
            <LazyLoadingComponent>
              <ServicesRequest />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DV_DAO_TAO_CAP_CHUNG_CHI,
          element: (
            <LazyLoadingComponent>
              <DiplomaRequest />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THONG_KE_CHI_TIET,
          element: (
            <LazyLoadingComponent>
              <StatisticDetail />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THONG_KE_CHI_TIET_XET_NGHIEM,
          element: (
            <LazyLoadingComponent>
              <StatisticDetailTesting />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THONG_KE_CHI_TIET_DANH_SACH_MAU,
          element: (
            <LazyLoadingComponent>
              <StatisticDetailListSample />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THONG_KE_CHI_TIET_DXN,
          element: (
            <LazyLoadingComponent>
              <StatisticDetailTestPoint />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_MUC_DICH_VU,
          element: (
            <LazyLoadingComponent>
              <ServicePost />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THOI_GIAN_LAM_VIEC,
          element: (
            <LazyLoadingComponent>
              <BusinessHour />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THONG_TIN_CA_NHAN,
          element: (
            <LazyLoadingComponent>
              <ProfilePage />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.QUAN_LY_TAI_SAN,
          element: (
            <LazyLoadingComponent>
              <Infrastructure />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.BANG_LUONG,
          element: (
            <LazyLoadingComponent>
              <SalaryManager />
            </LazyLoadingComponent>
          ),
        },
      ],
    },
    //  USER
    {
      element: (
        <LazyLoadingComponent>
          <PrivateRoutes />
        </LazyLoadingComponent>
      ),
      children: [
        {
          path: ROUTER.KHOA_HOC_CUA_TOI,
          element: (
            <LazyLoadingComponent>
              <MyCourse />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THANH_TOAN,
          element: (
            <LazyLoadingComponent>
              <PayDelivery />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.GIO_HANG,
          element: (
            <LazyLoadingComponent>
              <CardShopping />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.QUAN_LY_PHIEU_YEU_CAU,
          element: (
            <LazyLoadingComponent>
              <MyOrder />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SO_YEU_LY_LICH,
          element: (
            <LazyLoadingComponent>
              <Resume />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.TAI_KHOAN_CUA_TOI,
          element: (
            <LazyLoadingComponent>
              <MyAccount />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.HO_SO_CA_NHAN,
          element: (
            <LazyLoadingComponent>
              <Dashboard />
            </LazyLoadingComponent>
          ),
        },
      ],
    },

    {
      element: (
        <LazyLoadingComponent>
          <PublicRouters />
        </LazyLoadingComponent>
      ),
      children: [
        {
          path: ROUTER.DICH_VU,
          element: (
            <LazyLoadingComponent>
              <ServicePage />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SAN_PHAM,
          element: (
            <LazyLoadingComponent>
              <Product />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THANH_TOAN_SAN_PHAM,
          element: (
            <LazyLoadingComponent>
              <BuyProduct />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DAT_DICH_VU,
          element: (
            <LazyLoadingComponent>
              <BuyProduct />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_SAN_PHAM,
          element: (
            <LazyLoadingComponent>
              <DetailProduct />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CONG_NGHE,
          element: (
            <LazyLoadingComponent>
              <DetailTech />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THONG_BAO_TUYEN_DUNG,
          element: (
            <LazyLoadingComponent>
              <Recruitment />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_DOT_TUYEN,
          element: (
            <LazyLoadingComponent>
              <DetailRecruitmentSession />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_TUYEN_DUNG,
          element: (
            <LazyLoadingComponent>
              <DetailRecruitmentPosition />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANG_KY_DU_TUYEN,
          element: (
            <LazyLoadingComponent>
              <RecruitmentRegister />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.QUY_CHE_YEU_CAU,
          element: (
            <LazyLoadingComponent>
              <Regulation />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TRA_KET_QUA,
          element: (
            <LazyLoadingComponent>
              <Result />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TIN_TUC_XET_NGHIEM_NUOC,
          element: (
            <LazyLoadingComponent>
              <News id={0} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.GIA_CHI_TIEU_QCVN,
          element: (
            <LazyLoadingComponent>
              <QCVNTargetPrice />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.HOME,
          element: (
            <LazyLoadingComponent>
              <Home />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TIM_KIEM,
          element: (
            <LazyLoadingComponent>
              <Search />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CAC_CHI_TIEU,
          element: (
            <LazyLoadingComponent>
              <Targets />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CACH_LAY_MAU,
          element: (
            <LazyLoadingComponent>
              <GetSample />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CAC_GOI_DICH_VU,
          element: (
            <LazyLoadingComponent>
              <Services type={1} />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.DICH_VU_XET_NGHIEM,
          element: (
            <LazyLoadingComponent>
              <ExaminationServices />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_KHOA_HOC,
          element: (
            <LazyLoadingComponent>
              <Course />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANG_KY_KHOA_HOC,
          element: (
            <LazyLoadingComponent>
              <RegisterCourse />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TIN_TUC_DAO_TAO_CAP_CHUNG_CHI,
          element: (
            <LazyLoadingComponent>
              <TrainingNews />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_KHOA_HOC,
          element: (
            <LazyLoadingComponent>
              <DetailCourse />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_DICH_VU,
          element: (
            <LazyLoadingComponent>
              <DetailProduct />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.KQNC_THONG_TIN_HOI_THAO,
          element: (
            <LazyLoadingComponent>
              <DetailSeminar />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.KQNC_DANG_KY_HOI_THAO,
          element: (
            <LazyLoadingComponent>
              <RegisterSeminar />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.KQNC_HOI_THAO,
          element: (
            <LazyLoadingComponent>
              <Seminar />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.NANG_LUC_CHUYEN_MON,
          element: (
            <LazyLoadingComponent>
              <Specialize />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.LIEN_HE,
          element: (
            <LazyLoadingComponent>
              <Contact />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.DANH_SACH_TIN_TUC,
          element: (
            <LazyLoadingComponent>
              <News />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_CONG_NGHE,
          element: (
            <LazyLoadingComponent>
              <Tech />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.SKNN_NEWS,
          element: (
            <LazyLoadingComponent>
              <News id={1} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SKNN_PC_BNN,
          element: (
            <LazyLoadingComponent>
              <News id={2} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SKNN_NCKH,
          element: (
            <LazyLoadingComponent>
              <News id={3} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SKMT_TIN_TUC,
          element: (
            <LazyLoadingComponent>
              <News id={4} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SKMT_SKTH,
          element: (
            <LazyLoadingComponent>
              <News id={5} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SKMT_VSATLD,
          element: (
            <LazyLoadingComponent>
              <News id={6} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SKMT_TLLD,
          element: (
            <LazyLoadingComponent>
              <News id={7} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.SKMT_GSCLN,
          element: (
            <LazyLoadingComponent>
              <News id={8} />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.CDT_CDVTD,
          element: (
            <LazyLoadingComponent>
              <News id={9} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CDT_GSHTCM,
          element: (
            <LazyLoadingComponent>
              <News id={10} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CDT_KHCDT,
          element: (
            <LazyLoadingComponent>
              <News id={11} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CDT_WHO,
          element: (
            <LazyLoadingComponent>
              <News id={12} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.MEDIA,
          element: (
            <LazyLoadingComponent>
              <News id={19} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DVCN_DANG_KY_KHAM,
          element: (
            <LazyLoadingComponent>
              <ExaminationRegister />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DVCN_TIN_TUC_KSK,
          element: (
            <LazyLoadingComponent>
              <Examination />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DVCN_DANG_KY_QTM,
          element: (
            <LazyLoadingComponent>
              <Services type={2} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DVCN_TIN_TUC_QTM,
          element: (
            <LazyLoadingComponent>
              <Monitoring />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TIN_TUC_CHI_TIET,
          element: (
            <LazyLoadingComponent>
              <NewsDetail />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.GIOI_THIEU_TONG_QUAN,
          element: (
            <LazyLoadingComponent>
              <IntroductionOverview />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DICH_VU_ONSITE,
          element: (
            <LazyLoadingComponent>
              <ServiceOnsite />
            </LazyLoadingComponent>
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <LazyLoadingComponent>
          <NotFound />
        </LazyLoadingComponent>
      ),
    },
  ]
  const element = useRoutes(routes)
  return (
    <div className="layout-center">
      <div className="layout-max-width">
        {loading ? (
          <div className="loading-center" style={{ height: "100vh" }}>
            <Spin />
          </div>
        ) : (
          element
        )}
      </div>
      {!!modalLoading && <ModalLoading />}
    </div>
  )
}

export default App
