const ROUTER = {
  // LOGIN: "/login",
  HOME: "/",

  DICH_VU: "/dich-vu",

  SAN_PHAM: "/san-pham/:id",

  GIOI_THIEU_TONG_QUAN: "/gioi-thieu",

  DANH_SACH_TIN_TUC: "/danh-sach-tin-tuc/:id",

  THONG_BAO_TUYEN_DUNG: "/tuyen-dung",

  LIEN_HE: "/lien-he",

  NEWS: "/noi-dung",

  TIN_TUC_CHI_TIET: "/noi-dung/:id",
  // NEWS
  MEDIA: "/media",
  DANH_SACH_CONG_NGHE: "/danh-sach-cong-nghe",
  CONG_NGHE: "/cong-nghe/:id",

  KQNC_HOI_THAO: "/hoi-thao",
  KQNC_THONG_TIN_HOI_THAO: "/kqnc/thong-tin-hoi-thao/:id",
  KQNC_DANG_KY_HOI_THAO: "/kqnc/dang-ky-hoi-thao",
  KQNC_VAN_BAN_PHAP_QUY: "/kqnc/van-ban-phap-quy",

  DAO_TAO_CAP_CHUNG_CHI: "/dao-tao-cap-chung-chi",

  DANH_SACH_KHOA_HOC: "/danh-sach-khoa-hoc",
  CHI_TIET_KHOA_HOC: "/chi-tiet-khoa-hoc/:id",
  DANG_KY_KHOA_HOC: "/dang-ky-khoa-hoc/:id",
  TIN_TUC_DAO_TAO_CAP_CHUNG_CHI: "/dtccc/tin-tuc",

  CAC_GOI_DICH_VU: "/cac-goi-dich-vu",
  CHI_TIET_DICH_VU: "/chi-tiet-dich-vu/:id",
  TU_VAN: "/tu-van",
  CHI_TIET_TUYEN_DUNG: "/chi-tiet-tuyen-dung/:id",
  CHI_TIET_DOT_TUYEN: "/chi-tiet-dot-tuyen/:id",
  DANG_KY_DU_TUYEN: "/dang-ky-du-tuyen/:id",

  // NEWS: "/tin-tuc",
  DANG_BAI: "/dang-bai",
  CHI_TIET_SAN_PHAM: "/chi-tiet-san-pham/:id",
  DICH_VU_CHI_TIET: "/dich-vu/:id",

  // USER
  GIO_HANG: "/gio-hang",
  THANH_TOAN: "/thanh-toan",
  THANH_TOAN_SAN_PHAM: "/thanh-toan-san-pham",
  DAT_DICH_VU: "/dat-dich-vu",
  KHOA_HOC_CUA_TOI: "/khoa-hoc-cua-toi",
  QUAN_LY_PHIEU_YEU_CAU: "/quan-ly-phieu-yeu-cau",
  TAI_KHOAN_CUA_TOI: "/tai-khoan-cua-toi",
  HO_SO_CA_NHAN: "/ho-so-ca-nhan",
  SO_YEU_LY_LICH: "/so-yeu-ly-lich",

  // ADMIN
  PHAN_QUYEN: "/phan-quyen",
  PHONG_BAN_CHUC_VU: "/phong-ban-chuc-vu",
  DANH_BA_NGUOI_DUNG: "/danh-ba-nguoi-dung",
  DANH_SACH_BAI_VIET: "/danh-sach-bai-viet",

  QUAN_LY_KHOA_HOC: "/quan-ly-khoa-hoc",
  QUAN_LY_SAN_PHAM: "/quan-ly-san-pham",
  DANH_MUC_DICH_VU: "/danh-muc-dich-vu",

  DANH_MUC_THE: "/danh-muc-the",
  DANH_SACH_YEU_CAU: "/danh-sach-yeu-cau",
  YEU_CAU_HO_TRO: "/yeu-cau-ho-tro",

  TONG_QUAN: "/tong-quan",
  DV_SAN_PHAM: "/dv-san-pham",
  DV_DAO_TAO_CAP_CHUNG_CHI: "/dv-dao-tao-cap-chung-chi",
  THONG_TIN_DON_VI: "/thong-tin-don-vi",
  DANH_SACH_HOI_THAO: "/danh-sach-hoi-thao",
  DANH_BA: "/danh-ba",
  THONG_KE_CHI_TIET: "/thong-ke-chi-tiet",

  TUYEN_DUNG: "/danh-sach-du-tuyen",
  DANH_SACH_TUYEN_DUNG: "/danh-sach-du-tuyen/:id",
  DANH_SACH_PHIEU_DICH_VU: "/danh-sach-phieu-dich-vu",
  THOI_GIAN_LAM_VIEC: "/thoi-gian-lam-viec",
  THONG_TIN_CA_NHAN: "/thong-tin-ca-nhan",
  QUAN_LY_TAI_SAN: "/quan-ly-tai-san",
  BANG_LUONG: "/bang-thuong",

  LOGIN_GOOGLE: "/dang-nhap-tu-mang-xa-hoi",
  TIM_KIEM: "/tim-kiem",
  SVG_VIEWER: "/svg-viewer",
}
export default ROUTER
